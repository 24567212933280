.navigation_container {
  max-width: 960px;
  margin: 0 auto;
  padding: 10px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation_site ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.navigation_site ul li {
  color: var(--color_dcffe3);
  font-size: 0.7rem;

  transition: 0.5s;

  cursor: pointer;
}

.navigation_site ul li:hover {
  background-color: var(--color_cad2cc);
}

.navigation_config {
  display: flex;
  align-items: center;
  gap: 15px;

  border-bottom: 1px dotted var(--color_abbeb0);
}

.navigation_config a {
  font-size: 0.73rem;
  color: var(--color_fff);
  cursor: pointer;
  text-transform: uppercase;
}

.navigation_config a:hover {
  text-decoration: underline;
}

@media (max-width: 959px) {
  .navigation_container {
    display: block;
  }
  .navigation_config {
    margin-top: 25px;
  }
}
