.container_footer {
  background-color: var(--color_00420c);

  margin-top: 50px;
  height: 500px;
}

.footer {
  max-width: 960px;
  margin: 0 auto;
  padding: 25px 0;

  display: flex;
  justify-content: space-between;
}

.matters h2,
.about h2,
.network h2,
.about_this h2 {
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--color_61b05f);

  margin-bottom: 10px;
}

.matters ul li,
.about ul li,
.network ul li,
.about_this ul li {
  margin-bottom: 5px;
}

.matters ul li a,
.about ul li a,
.network ul li a,
.about_this ul li a {
  color: var(--color_fff);

  font-size: 0.875rem;
  text-transform: capitalize;
}

.matters ul li a:hover,
.about ul li a:hover,
.network ul li a:hover,
.about_this ul li a:hover {
  text-decoration: underline;
}

.container_footer_baseboard {
  background-color: var(--color_002907);
  height: 100px;
}

.img_baseboard {
  max-width: 960px;
  margin: 0 auto;
  padding: 25px 0;
}

.img_baseboard img {
  width: 107px;
  height: 50px;
}

.creators {
  max-width: 960px;
  margin: 0 auto;
  padding: 15px 0;

  display: flex;
  align-items: center;
  gap: 15px;

  font-size: 0.875rem;
  line-height: 1rem;
}

.creators span {
  color: var(--color_333);
}

.creators h4 a {
  color: var(--color_0041b2);

  text-transform: capitalize;
  font-weight: 700;
}
