.container_bg {
  margin: 0 auto;

  background: radial-gradient(
    ellipse at center,
    var(--color_17882c) 1%,
    var(--color_00510f) 100%
  );
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 15px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--color_fff);
}

a.portal_preTitle {
  font-size: 0.85rem;
  text-transform: capitalize;
  color: var(--color_fff);
}

h1.portal_title {
  font-size: 2.75rem;
  font-weight: 700;
  line-height: 0.8rem;

  margin: 20px auto;

  text-transform: capitalize;
}

span.portal_subTitle {
  font-size: 1.1rem;

  text-transform: uppercase;
}

.navigation_search {
  background-color: var(--color_fff);
  padding: 4px 18px;
  border-radius: 4px;
}

.navigation_search input {
  padding-left: 2px;
  border: none;
}

.navigation_search input::placeholder {
  font-size: 1rem;
}

.navigation_search .btn_search {
  position: relative;
  border: none;
  background: var(--color_fff);
}

.navigation_search .btn_search img {
  width: 18px;
  height: 18px;
}

/* open_sansextrabold,'Open Sans',Arial,Helvetica,sans-serif */

@media (max-width: 959px) {
  .navigation_search {
    display: none;
  }
}
