.section_row {
  background-color: var(--color_e8e7e7);
  padding: 0 10px 0 0;
  display: flex;
}

.destaque {
  width: 20%;
}

.destaque h2 {
  padding: 7px 50px 7px 5px;

  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;

  color: var(--color_fff);
  background-color: var(--color_717782);

  text-align: center;
  text-transform: uppercase;
}

.lista_destaque {
  display: flex;
  gap: 10px;
  margin-left: 45px;
}

.itens_destaque a {
  font-size: 0.6875rem;
  line-height: 1;
  padding: 7px 5px;

  color: var(--color_333);

  text-transform: uppercase;
}

.itens_destaque a:hover {
  text-decoration: underline;
}

@media (max-width: 959px) {
  .section_row {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .lista_destaque {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
