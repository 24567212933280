.about_bg {
  background-color: var(--color_00420c);
  border-bottom: 2px solid var(--color_00420c);
}

.about_container {
  max-width: 960px;
  margin: 0 auto;
}

.about_container_nav {
  width: 100%;
}

.list_nav_about {
  display: flex;
  gap: 15px;

  justify-content: center;
}

.list_nav_about li {
  padding: 3px;
}

.list_nav_about li a {
  color: var(--color_fff);
  font-size: 0.875rem;

  text-transform: capitalize;
  cursor: pointer;
}

.list_nav_about li a:hover {
  text-decoration: underline;
}

@media (max-width: 959px) {
  .list_nav_about {
    flex-direction: column;
  }
}
