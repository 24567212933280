.date {
  text-align: center;
  flex: 1;
}

.custom img {
  width: 170px;
  margin-bottom: 10px;
}

.custom-date {
  width: 160px;
  margin: auto auto 10px 8px;

  background-color: var(--color_eaebee);
}

.custom-date h3 {
  margin-bottom: 25px;

  font-size: 0.875rem;
  line-height: 1;

  padding: 12px 12px 16px 10px;

  text-transform: uppercase;

  color: var(--color_00500f);

  border-top: 3px solid var(--color_00500f);
}

.custom-date-codigo {
  width: 160px;
  margin: auto auto 10px 8px;

  background-color: var(--color_eaebee);
}

.custom-date-codigo h3 {
  margin-bottom: 25px;

  font-size: 0.875rem;
  line-height: 1;

  padding: 12px 12px 16px 10px;

  text-transform: uppercase;

  border-top: 3px solid var(--color_00500f);
}

.custom-date-codigo h3 a {
  color: var(--color_00500f);
}
