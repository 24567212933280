.main_container {
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 0;
}

.container_home {
  margin-top: 25px;

  display: flex;
  /* align-items: center; */
}

.content {
  text-align: center;
  flex: 4;
}

.imagePrevious img {
  height: 285px;
}

.container_notices {
  display: flex;
  justify-content: space-between;

  margin-top: 25px;
}

.noticesleft h4 a,
.noticescenter h4 a,
.noticesright h4 a {
  font-size: 0.875rem;
  font-weight: 600;

  color: #333;

  text-transform: uppercase;
}

@media (max-width: 959px) {
  .container_notices {
    flex-direction: column;
  }
  .noticesleft,
  .noticescenter,
  .noticesright {
    margin-bottom: 25px;
  }
}
